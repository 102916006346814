import { graphql } from 'gatsby';
import React from 'react';

import Hat from 'components/shared/hat';
import Layout from 'components/shared/layout';
import Post from 'components/shared/post';

interface GrapqlData {
  markdownRemark: {
    frontmatter: {
      title: string;
    };
    htmlAst: string;
  };
}

const ConsentPage = ({ data }: { data: GrapqlData }) => {
  const {
    frontmatter: { title },
    htmlAst,
  } = data.markdownRemark;

  return (
    <>
      <Hat pageName="Souhlas se zpracováním osobních údajů">
        <meta name="robots" content="noindex, nofollow" />
      </Hat>
      <Layout>
        <Post htmlAst={htmlAst} title={title} />
      </Layout>
    </>
  );
};

export default ConsentPage;

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { id: { eq: "consent-page" } }) {
      frontmatter {
        title
      }
      htmlAst
    }
  }
`;
